import React, { useContext } from 'react';
import { GlobalDataContext } from '../../context/context';
import { ButtonContent } from '../global/boton/ButtonContent';
import imgDefault from '../../assets/image/placeholder.png';
import { VscDebugBreakpointData } from 'react-icons/vsc';

const BlockNine = ({ title, text, listsAbout, listsServices, image, sloganPrincipal }) => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <section className='w-full h-[100vh] my-72 md:my-40 lg:my-20 relative flex items-center justify-center bg-white'>
            <div className='w-full h-[75%] bg-blend-soft-light bg-cover bg-center bg-no-repeat bg-fixed' style={{backgroundImage: `url("${image ? image : imgDefault}")` }} ></div>
            <div data-aos="fade-right" className='absolute w-[70%] max-w-[600px] flex-col h-auto lg:right-[10%] bg-white shadow-xl z-10 p-10 py-[6%] text-center'>
            {
                        sloganPrincipal ?
                            rpdata?.dbPrincipal?.licensed ?
                                <h2 className='pb-3 capitalize'>
                                    {rpdata?.dbPrincipal?.licensed}
                                </h2>
                                : <h3 className='pb-10 capitalize'>
                                    {
                                        rpdata?.dbPrincipal?.exprYears ?
                                            `we have ${rpdata?.dbPrincipal?.exprYears} years of experience`
                                            : 'we have many years of experience'
                                    }
                                </h3>
                            :
                            <h2 className='pb-5 capitalize'>
                                {
                                    title ? title :
                                        <span className='lowercase text-[18px]'>
                                            {`default title={'frase'}`} o
                                            {` agregar licencia o años de experienceias sloganPrincipal={true}`}
                                        </span>
                                }
                            </h2>
                    }
                    <p className='pb-5'>
                        {
                            text ? text :
                                <span>{`para agregar el texto -> text={'description'}`}
                                    <br />{'para agregar lista de about -> listsAbout={true}'}
                                    <br />{'para agregar lista de servicios -> listsServices={true}'}
                                </span>
                        }
                    </p>
                    {
                        listsAbout ?
                            <ul className='grid grid-cols-1 md:grid-cols-2 pb-[40%] '>
                                {
                                    rpdata?.dbAbout?.[0].list.length > 1 ?
                                        rpdata?.dbAbout?.[0].list.map((item, index) => {
                                            return (

                                                <li key={index} className="py-2 flex items-center">
                                                    <VscDebugBreakpointData />
                                                    <span className="pl-2">{item}</span>
                                                </li>
                                            )
                                        })
                                        : null
                                }
                            </ul>
                            : null
                    }
                    {
                        listsServices ?
                            <ul className="grid grid-cols-1 md:grid-cols-2 pb-5">
                                {rpdata?.dbServices?.slice(0, 6).map((item, index) => {
                                    return (
                                        <li key={index} className="py-1 flex items-center">
                                            <VscDebugBreakpointData />
                                            <span>{item.name}</span>
                                        </li>
                                    )
                                })}
                            </ul>
                            : null
                    }
                    <div className=' self-center '>
                        <ButtonContent />
                    </div>
            </div>
        </section>

    )

}

export default BlockNine;